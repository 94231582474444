import React from 'react'
import { Link } from 'gatsby'

const Navigation = ({ location }) => {
  const pathname = location && location.pathname;
  return (
    <>
      <Link className={"site-nav-item" + (pathname === "" || pathname === "/" ? " active" : "")} to="/">Home</Link>
      <Link className={"site-nav-item" + (pathname === "/about" ? " active" : "")} to="/about">About</Link>
      <Link className={"site-nav-item" + (pathname === "/snippets" ? " active" : "")} to="/snippets">Snippets</Link>
      <Link className={"site-nav-item" + (pathname === "/gallery" ? " active" : "")} to="/gallery">Stuff To Look At</Link>
      <Link className={"site-nav-item" + (pathname && pathname.startsWith("/blog") ? " active" : "")} to="/blog">Blog</Link>
    </>
  )
}

export default Navigation
