import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, withPrefix } from 'gatsby'
import { Parallax } from 'react-parallax'

import HeroImg from '../../img/hero.png'

import Navigation from './Navigation'
import withSiteMetadata from "./siteMetadata"

// Styles
import '../../styles/app.css'

const DefaultLayout = ({ isHome, title, children, location }) => {
  const { title: siteTitle, description } = withSiteMetadata()
    return (
        <>
            <Helmet>
                <html lang={"en"} />
                <title>{siteTitle + (title ? " | " + title : "")}</title>
                <meta name="description" content={description} />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href={`${withPrefix('/')}img/favicon.jpg`}
                />
                <link
                  rel="icon"
                  type="image/png"
                  href={`${withPrefix('/')}img/favicon.jpg`}
                  sizes="32x32"
                />
                <link
                  rel="icon"
                  type="image/png"
                  href={`${withPrefix('/')}img/favicon.jpg`}
                  sizes="16x16"
                />
            </Helmet>
            <div className="viewport">
                <div className="viewport-top">
                    <Parallax
                      bgImage={HeroImg}
                      strength={300}
                    >
                    <header className="site-head" style={{background:"none"}}>
                      <div className="container">
                        <div className="site-mast">
                          <div className="site-mast-left">
                            <Link to="/" style={{textDecoration:"none"}}>
                              <img className="site-logo" src={"/img/logo.svg"} alt={"Old Trout Bum Logo"} style={{ width:"55px", height:"55px"}} />
                              <span style={{color: "white", fontWeight:"bold", marginLeft:"5px", fontSize:"2em"}}>otb</span>
                            </Link>
                          </div>
                        </div>
                          { isHome ?
                              <div className="site-banner">
                                <h1 className="site-banner-title"><span>Old</span> <span>Trout</span> <span>Bum</span></h1>
                                <p className="site-banner-desc">A WEBSITE FOR FLY FISHERMEN AND WOMEN WHO WANT TO DREAM A LITTLE, LEARN A LITTLE, FIND INSPIRATION, GIVE BACK AND PAY IT FORWARD</p>
                              </div> :
                              null}
                          <nav className="site-nav">
                            <div className="site-nav-left">
                              <Navigation data={{}} navClass="site-nav-item" location={location} />
                            </div>
                            <div className="site-nav-right">
                              <Link style={{whiteSpace:"nowrap"}} className={"site-nav-button"+ (location && location.pathname === "/giveback" ? " active" : "")} to="/giveback">Give Back</Link>
                            </div>
                          </nav>
                        </div>
                      </header>
                    </Parallax>
                    <main className="site-main">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <div style={{backgroundColor:"#15171A", height:"75px", display: "flex", justifyContent:"center",alignItems:"center"}}>
                      <span role="button" aria-label="scroll up" className="arrow up" style={{cursor:"pointer", marginTop:"20px"}} onClick={()=>{
                        window.scrollTo({top: 0, behavior: 'smooth'});
                      }}></span>
                    </div>
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <Link to="/">Old Trout Bum</Link> © 2021
                            </div>
                            <div className="site-foot-nav-right">
                                <Navigation data={{}} navClass="site-foot-nav-item" location={location} />
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
}

DefaultLayout.propTypes = {
  isHome: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
